.images-wrap {
    transition: transform 1s;
}

.each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
}

.each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
}

.dia-title-top-center {
    position: fixed;
    left: 50%;
    top: 50px;
    transform: translate(-50%, -50%);
    margin: 0 auto;
}

.dia-text-bottom-center {
    position: fixed;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, -50%);
    margin: 0 auto;
}

/*****************
==> SIDEBAR
******************/

.sidebar-toggle {
    position: absolute;
    top: 2rem;
    left: 2rem;
    font-size: 3rem;
    background: transparent;
    border-color: transparent;
    cursor: pointer;
}

.sidebar-close-btn {
    font-size: 1.75rem;
    background: transparent;
    border-color: transparent;
    transition: all 1s ease;
    cursor: pointer;
}

.sidebar-close-btn:hover {
    transform: rotate(360deg);
}

.show-sidebar {
    display: none;
}

.sidebar-open {
    animation: openSidebar 0.4s ease-out forwards;
}

.sidebar-closed {
    animation: closeSidebar 0.4s ease-out forwards;
}

@keyframes openSidebar {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes closeSidebar {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

/******************EXIT SIDEBAR*******************/

.form-preview-img {
    flex: 0 1 auto;
    width: 150px;
    height: 100px;
    position: absolute;
    right: 10%;
    bottom: 66%;
}

.h75 {
    height: 75%;
}

@media only screen and (max-width: 1280px) {
    .form-preview-img {
        position: static;
        height: 14rem;
    }
    .form-preview {
        max-width: 15rem;
    }
}

.video-hidden {
    position: absolute;
    opacity: 0.00001;
}

.inner-text-slide {
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
}

.inner-active-slide {
    left:29%;
    top:50%;
    transform: translate(-50%, -50%);
}

.absolute-center {
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
}

textarea::-webkit-scrollbar {
    width: 12px;
}

textarea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}

textarea::-webkit-scrollbar-thumb {
    background-color: #39bfc1;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

/*****************
==> HOMEPAGE
******************/

.home-hero {
    margin: 50px 0 100px 0;
}

.home-main-title {
    margin: 50px 0 0 0;
    font-size: 55px;
    color: white;
    text-align: center;
}

.home-main-description {
    font-size: 25px;
    color: white;
    text-align: center;
    font-weight: lighter;
    max-width: 80%;
    margin: auto;
}

.rule-section-title {
    color: white;
    font-size: 30px;
    margin: 10px 0 10px 0 ;
}

.rules {
    margin: 50px 0 0 0;
    display: flex;
    flex-direction: column;
}

.rules ul {
    list-style-type: circle;
}

.rules li {
    margin: 5px 0;
    color: white;
}

.section-title {
    display: flex;
    justify-content: center;
    margin: 20px 0 20px 0;
}

.section-title h1 {
    font-size: 40px;
    color: white;
    border-bottom: 1px solid white;
}

.rules-section {
    max-width: 50%;
    margin: auto;
}

.markdown-text {
    color: white;
}

.rule-section {
    margin-bottom: 30px;
}
